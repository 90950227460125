import type ReceiptFinderResponseDto from "~/models/receipt/ReceiptFinderResponse";
import type ExternalCustomerDto from "~/models/customer/ExternalCustomer";
import type VendorInformationDto from "~/models/customer/VendorInformation";
import type { AnybillResult } from "~/models/general/AnybillResponse";
import type ReceiptFindDto from "~/models/receipt/ReceiptFindDto";
import { AnybillApiPaths } from "~/constants/AnybillApiPaths";
import { handleAnybillResult } from "~/helpers/ErrorHandler";

export class AnybillProvider {
    private static _instance: AnybillProvider;


    static get Instance(): AnybillProvider {
        if (AnybillProvider._instance === undefined) {
            AnybillProvider._instance = new AnybillProvider();
        }
        return AnybillProvider._instance;
    }

    public async getCustomers(): Promise<AnybillResult<ExternalCustomerDto[]>> {
        const data = await $fetch(AnybillApiPaths.GET_CUSTOMERS, {
            method: "GET",
            timeout: 30000,
        });
        return handleAnybillResult<ExternalCustomerDto[]>(data as AnybillResult<ExternalCustomerDto[]>, "getCustomers");
    }

    public async getVendorInformation(identifier: string): Promise<AnybillResult<VendorInformationDto>> {
        const data = await $fetch(AnybillApiPaths.GET_VENDOR_INFORMATION(identifier), {
            method: "GET",
            timeout: 30000,
        });

        return handleAnybillResult<VendorInformationDto>(data as AnybillResult<VendorInformationDto>, "getVendorInformation");
    }

    public async findReceipt(receiptFindDto: ReceiptFindDto): Promise<AnybillResult<ReceiptFinderResponseDto[]>> {
        const data = await $fetch(AnybillApiPaths.FIND_RECEIPT, {
            method: "POST",
            body: receiptFindDto,
            timeout: 30000,
        });

        return handleAnybillResult<ReceiptFinderResponseDto[]>(data as AnybillResult<ReceiptFinderResponseDto[]>, "findReceipt");
    }
}