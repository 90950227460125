<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { LanguageModel } from "~/models/general/languageModel";

const open = ref(false);

const languages: LanguageModel[] = [
  new LanguageModel("de", "Deutsch", "/flags/flag-german.svg"),
  new LanguageModel("en", "English", "/flags/flag-english.svg"),
  new LanguageModel("fr", "Francais", "/flags/flag-french.svg"),
  new LanguageModel("pt", "Portuguese", "/flags/flag-portugal.svg"),
];

const name = (id: string) => languages.find((language) => language.id === id)?.name;

const icon = (id: string) => languages.find((language) => language.id === id)?.icon;

const { locale, availableLocales } = useI18n();

const closeDialog = () => (open.value = !open.value);
</script>

<template>
  <div class="locale-changer">
    <div class="d-flex align-end flex-column" style="position: relative">
      <v-row class="pa-2">
        <div class="d-flex flex-row" @click="open = !open">
          <img src="/icon-globe.svg" alt="globe-icon" class="mr-1" />
          <div class="font-weight-bold text-button text-uppercase">
            {{ locale }}
          </div>
        </div>
      </v-row>
      <v-card v-if="open" v-click-outside="closeDialog" class="language-switcher">
        <div
          v-for="(lang, i) in availableLocales"
          :key="i"
          class="d-flex flex-row items-center pa-1"
          @click="
            locale = lang;
            open = false;
          "
        >
          <img :src="icon(lang)" style="width: 15px" />
          <div
            :class="`mr-auto my-auto pl-2 font-weight-bold ${
              locale === lang ? 'text-decoration-underline' : 'text-medium-emphasis'
            }`"
          >
            {{ name(lang) }}
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.language-switcher {
  position: absolute;
  top: -360%; /* Adjust this value to position the card above the button */
  right: 2.5em; /* Align it to the right if needed */
  background: white;
  z-index: 100; /* Ensure it has a high z-index to appear on top of other components */
  border: 1px solid black;
  border-radius: 5px;
  padding: 1%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for better visibility */
}
</style>
