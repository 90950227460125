<script setup lang="ts">
import { useI18n } from "vue-i18n";
import LanguageDropdown from "./localization/language-dropdown.vue";

defineProps({
  onLogoClick: {
    type: Function,
  },
});

const { t, locale } = useI18n();

const privacyLink = computed(() =>
  locale.value !== "de"
    ? "https://www.anybill.de/en/rechtliche-seiten/datenschutzerklarung-getmy-anybill-de"
    : "https://www.anybill.de/rechtliche-seiten/datenschutzerklarung-getmy-anybill-de"
);
const imprintLink = computed(() =>
  locale.value !== "de" ? "https://anybill.de/en/imprint" : "https://anybill.de/imprint"
);
const currentYear = computed(() => new Date().getFullYear());
</script>

<template>
  <div class="bottom-0 py-5">
    <v-container class="pa-0">
      <v-col>
        <v-img
          src="/anybill_branding.svg"
          :height="20"
          :width="300"
          class="ml-auto mb-1"
          @click="onLogoClick ? onLogoClick() : {}"
        />

        <v-divider :thickness="1" class="border-opacity-100" color="black" />

        <!-- Text on the Left below the Divider -->
        <v-row justify="space-between" class="mx-1">
          <div class="pt-2" align="start">
            <span class="text-caption font-weight-medium"
              >©{{ currentYear }} anybill GmbH</span
            >
            <div class="d-flex flex-row">
              <a class="text-caption mr-2" :href="privacyLink" target="_blank">
                <span class="text-caption font-weight-medium" style="overflow: hidden">
                  {{ $t("global.footerDSE") }}
                </span>
              </a>
              <a class="text-caption" :href="imprintLink" target="_blank">
                <span class="text-caption font-weight-medium" style="overflow: hidden">
                  {{ $t("global.footerImprint") }}
                </span>
              </a>
            </div>
          </div>
          <LanguageDropdown class="pt-4 mr-4" />
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<style scoped>
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: black;
}
</style>
