import { datadogLogs } from "@datadog/browser-logs";
import type { AnybillResult } from "~/models/general/AnybillResponse";

export enum ErrorLevel {
  INFO,
  WARN,
  ERR,
}

export function handleAnybillResult<T>(result: AnybillResult<T>, callee: string): AnybillResult<T> {
  if (!result.success) {
    ErrorHandler.Instance.handleError(result.statusCode, result.errorMessage, result.errorCode, callee);
  }
  return result;
}

export default class ErrorHandler {
  private static _instance: ErrorHandler;

  private constructor() {
    const env = process.env.API_ENV;

    datadogLogs.init({
      clientToken: "pubb329580a54bf000eeb08fb4233e86793",
      site: "datadoghq.eu",
      sessionSampleRate: 100,
      service: "receipt-finder",
      env,
      beforeSend: log =>
        !log.message.includes("XHR error GET"),
    });
  }

  static get Instance(): ErrorHandler {
    if (ErrorHandler._instance === undefined) {
      ErrorHandler._instance = new ErrorHandler();
    }
    return ErrorHandler._instance;
  }

  private get isProductionBuild() {
    return process.env.NODE_ENV === "production";
  }

  handleError(statusCode: number, errorMessage: string | null, errorCode: string | null, callee: string) {
    if (this.isProductionBuild) {
      if (statusCode !== 404) {
        try {
          datadogLogs.logger.error(errorMessage ?? errorCode ?? "error", {
            data: errorCode ?? "",
            callee,
            status: statusCode || "no-api-request",
            env: process.env.API_ENV,
          });
        }
        catch {
          console.error(errorMessage);
          console.error(errorCode);
          console.error(statusCode);
        }
      }
    }
    else {
      console.error(`callee: ${callee}`);
      console.error(errorMessage);
      console.error(errorCode);
      console.error(statusCode);
    }
  }
}
